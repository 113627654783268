import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import SEO from '@components/seo';

import BackNavBar from '@src/components/backNavBar';
import Contact from '@components/contact';
import Hero from '../../components/hero';
import Layout from '@components/layout';

import URLSection from '@components/index-components/urlSection';

import * as styles from './blocket.module.css';

const BlocketVillkor = ({ data }) => {
  const {
    contentfulPage: {
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
    },
  } = data;
  const headLine = data.contentfulPage.headLine;
  const contactHeader = data.contentfulPage.pageContactHeader || '';
  const lists = data.contentfulPage.pageContent[0].links.lists;

  return (
    <Layout newStyle>
      <SEO title={headLine} noIndex />
      <Hero
        breadcrumbs={[
          { path: '/', name: 'Hem' },
          { path: '/allmanna-villkor', name: 'Allmänna Villkor' },
        ]}
        title={pageHeader}
        subtitleMarkdown={pageHeaderDescription}
      />
      <div className={styles.contentWrapper}>
        <BackNavBar text="Tillbaka till villkor" back="/allmanna-villkor" />
        <div className={styles.urlSectionsContainer}>
          <URLSection lists={lists} />
        </div>
      </div>
      <Contact headerText={contactHeader} isLargeContact={true} />
    </Layout>
  );
};

export default BlocketVillkor;

BlocketVillkor.propTypes = {
  data: propTypes.object,
};

export const query = graphql`
  query BlocketVillkorQuery {
    contentfulPage(pageUrl: { eq: "/allmanna-villkor/blocket" }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      pageContent {
        ... on ContentfulLinkSection {
          links {
            lists {
              header
              urls {
                href
                text
              }
            }
          }
          header
        }
      }
    }
  }
`;
